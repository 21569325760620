<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:500px;overflow:auto;">

                <div class="form-group table-responsive">
                    <table class="table ">
                        <thead>
                        <tr class="alert alert-light">
                            <th scope="col" width="30%">SKU</th>
                            <th scope="col">价格</th>
                            <th scope="col">数量</th>
                            <th scope="col">已收货</th>
                            <th scope="col">待收货</th>
                            <th scope="col" width="20%">收货货架</th>
                            <th scope="col">收货</th>
                        </tr>
                        </thead>
                        <tbody>

                        <template v-for="(item, idx) in data_form.details">
                            <tr :key="idx" v-if="item.quantity > 0">
                                <td>
                                    <div  class="text-dark-65 font-weight-bold line-height">
                                        {{item.sku}}
                                    </div>
                                    <div style="margin-top:10px;">
                                        <template v-for="attr in JSON.parse(item.attr_info)">
                                            <span :key="attr" class="label label-primary label-inline font-weight-lighter mr-2">{{attr}}</span>
                                        </template>
                                    </div>
                                </td>
                                <td>{{item.price}}</td>
                                <td>{{item.quantity}}</td>
                                <td>{{item.receiving_quantity?item.receiving_quantity:0}}</td>
                                <td>{{item.quantity - item.receiving_quantity}}</td>
                                <td>
                                    <treeselect
                                            :options="shelf_list"
                                            :default-expand-level="1"
                                            placeholder="请选择获货架"
                                            v-model="data_form.details[idx].shelf_id"
                                    />
                                </td>
                                <td>
                                    <input type="text" @input="onIntInput" name="receiving_quantity" v-model="data_form.details[idx].receiving" class="form-control" placeholder="">
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>

            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>

    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';
    import Treeselect from '@riophae/vue-treeselect';
    import {getPurchasementDetail, getShelf, receivePurchasement} from '@/api/purchasement';

    export default {
        name: "purchasement_receive",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {
            var _this = this;
            getPurchasementDetail({'purchase_id': _this.data_form.id}).then(function(res) {
                if(res && res.response){
                    _this.data_form.details = res.response;
                }
            });

            getShelf({'warehouse_id': _this.data_form.warehouse_id}).then(function(res) {
                if(res && res.response){
                    var options = [];
                    res.response.forEach(function(val){
                        options.push({'id': val.id, 'label': val.shelf_number});
                    });
                    _this.shelf_list = options;
                }
            });
        },
        components :{
            Treeselect
        },
        watch: {

        },
        data() {
            return {
                shelf_list: [],
                data_form:{
                    id: this.dialog_data.form_data.id,
                    warehouse_id: this.dialog_data.form_data.warehouse_id,
                    product_id: this.dialog_data.form_data.product_id,
                    details: []
                },
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {

                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                receivePurchasement(_this.data_form).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            regionChange (data) {
                this.data_form.struct_address = data;
            },
            onFloatInput(e){
                e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
            },
            onIntInput(e){
                e.target.value = parseInt(e.target.value) || null;
            },
            timeToDate(value) {
                return KTUtil.timestamp2date(value);
            }
        }
    }
</script>
