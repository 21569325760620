<template>
    <v-app style="background: transparent" :class="{}">
        <template v-if="route_children">
            <router-view></router-view>
        </template>
        <template v-else>
            <!-- begin:: Content Head -->
            <KTSubheader
                    v-if="nav.subheaderDisplay"
                    v-bind:breadcrumbs="nav.breadcrumbs"
                    v-bind:title="nav.pageTitle"
            />
            <!-- end:: Content Head -->

            <div class="d-flex flex-column-fluid">
                <!--begin::Container-->
                <div class="container">
                    <div class="card condition" role="alert">
                        <div class="box-card">
                            <p>采购状态：</p>
                            <ul>
                                <li class="" style="">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.status===''?'active':'']"
                                       @click="search_form.status=''"
                                    >全部</a>
                                </li>
                                <li v-for="(item) in status_list"
                                    v-bind:key="item.value">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.status===item.value?'active':'']"
                                       @click="search_form.status=item.value"
                                    >{{item.text}}</a>
                                </li>

                            </ul>
                        </div>

                        <div class="box-card">
                            <p>采购仓库：</p>
                            <ul>
                                <li class="" style="">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.warehouse_id==''?'active':'']"
                                       @click="search_form.warehouse_id=''"
                                    >全部</a>
                                </li>
                                <li v-for="(item) in warehouse_list"
                                    v-bind:key="item.id">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.warehouse_id==item.id?'active':'']"
                                       @click="search_form.warehouse_id=item.id"
                                    >{{item.name}}</a>
                                </li>


                            </ul>
                        </div>

                    </div>
                    <!--begin::Card-->
                    <div class="card card-custom">
                        <div class="card-header flex-wrap border-0 pt-6 pb-0">
                            <div class="card-title">
                                <h3 class="card-label">
                                    采购列表
                                    <span class="d-block text-muted pt-2 font-size-sm"></span>
                                </h3>
                            </div>
                            <div class="card-toolbar">

                                <!--begin::Button-->
                                <a href="javascript:;" class="btn btn-primary font-weight-bolder" @click="addPurchasement()">
                                    新增采购
                                </a>
                                <!--end::Button-->
                            </div>
                        </div>
                        <div class="card-body">
                            <!--begin: Search Form-->

                            <!--begin::Search Form-->
                            <div class="mb-7">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 col-xl-12">
                                        <div class="row align-items-center">
                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">开始时间:</label>
                                                    <KTDatePicker
                                                            v-model="search_form.begin_time"
                                                    ></KTDatePicker>
                                                </div>
                                            </div>
                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">结束时间:</label>
                                                    <KTDatePicker
                                                            v-model="search_form.end_time"
                                                    ></KTDatePicker>
                                                </div>
                                            </div>

                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">产品名称:</label>
                                                    <input type="text" class="form-control" placeholder="产品名称" v-model="search_form.product_title">
                                                </div>
                                            </div>

                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">采购单号:</label>
                                                    <input type="text" class="form-control" placeholder="采购单号" v-model="search_form.purchase_number">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-center mt-5">

                                            <div class="col-md-3 my-2 my-md-0">
                                                <a href="#" class="btn btn-light-primary px-6 font-weight-bold" v-on:click="searchList()">Search</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end::Search Form-->
                            <!--end: Search Form-->


                            <KTDatatable
                                    v-bind:list="list"
                                    v-bind:column="column"
                                    v-bind:showSelect="false"
                                    v-bind:showPagination="true"
                                    v-bind:pageSize="page.limit"
                                    v-bind:pageCount="page.pageCount"
                                    v-bind:total="page.total"
                                    @operateHandler="operateHandler"
                                    @pagechangeHandler="pagechangeHandler"
                            ></KTDatatable>

                        </div>
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Container-->
            </div>

            <v-dialog v-model="dialog_show" persistent max-width="1200px">
                <component
                        :key="dialog_id"
                        :title="dialog_title"
                        :visible="dialog_show"
                        :is="dialog_view"
                        :dialog_data="dialog_data"
                        @opeareResultHandler="opeareResultHandler"></component>
            </v-dialog>
        </template>
    </v-app>

</template>
<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTDatatable from "@/view/content/tables/Datatable.vue";
    import KTDatePicker from "@/view/content/date/DatePicker.vue";
    import receivePurchasement from '@/view/pages/purchasement/ReceivePurchasement';
    import KTUtil from "@/assets/js/components/util";
    import {getPurchasementList, getWareHouse, deletePurchasement, updatePurchasementStatus} from '@/api/purchasement';


    export default {
        name: "purchasement_view",
        components: {
            KTSubheader,
            KTDatatable,
            KTDatePicker
        },
        created: function(){
            var _this = this;

            //判断路由
            if('/' + this.$route.name == this.$route.path){
                getWareHouse().then(function(res) {
                    if(res && res.response){
                        _this.warehouse_list = res.response;
                    }
                });
                _this.searchList();
            } else {
                _this.route_children = true;
            }
        },
        data() {
            return {
                route_children: false,
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/purchasement', 'title':'采购管理'}],
                    pageTitle: '仓库'
                },
                warehouse_list: [],
                status_list: [
                    {'value': 0, 'text': '待处理'},
                    {'value': 1, 'text': '已收货'},
                    {'value': 4, 'text': '退款中'},
                    {'value': 5, 'text': '退款完成'},
                    {'value': 3, 'text': '已完成'},
                ],
                list: [],
                column: [{
                    field: 'purchase_number',
                    title: '采购单号',
                    width: 150
                },{
                    field: 'channel',
                    title: '采购信息',
                    width: 250,
                    formatter: function(row) {
                        let channel = '';
                        if(row.channel == 1){
                            channel = '手工采购'
                        } else if(row.channel == 2) {
                            channel = '1688采购'
                        } else if(row.channel == 3) {
                            channel =  '其他采购'
                        }
                        let html = '<div class="ml-2">' +
                                    '<div class="line-height">产品：'+ row.product_title +'</div>' +
                                    '<div class="line-height">供应商：'+ row.supplier_name +'</div>' +
                                    '<div class="line-height">类型：'+ channel +'</div>';

                        if(row.channel != 3){
                            html += '<div class="line-height">仓库：'+ row.warehouse_name +'</div>';
                        }
                        html += '</div>';
                        return html;

                    }
                },{
                    field: 'amount',
                    title: '采购金额',
                },{
                    field: 'status',
                    title: '状态',
                    formatter: function(row) {
                        if(row.status == 0){
                            return '待处理'
                        } else if(row.status == 1) {
                            return '已收货'
                        } else if(row.status == 3) {
                            return '已完成'
                        } else if(row.status == 4) {
                            return '退款中'
                        } else if(row.status == 5) {
                            return '退款完成'
                        }
                    }
                },{
                    field: 'create_time',
                    title: '创建时间',
                    width: 150,
                    formatter: function(row) {
                        return KTUtil.timestamp2date(row.create_time);
                    }
                },{
                    field: 'creator',
                    title: '采购人',
                },{
                    field: 'operate',
                    title: '操作',
                    width: 150,
                    formatter: function(row){
                        let html = '';
                        if(row.status == 0) {
                            html = '' +
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="收货">'+
                                '<i class="la la-dolly icon-lg"></i>'+
                                '</a>'+
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="修改">'+
                                '<i class="la la-edit icon-lg"></i>'+
                                '</a>'+
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="删除">'+
                                '<i class="la la-trash-o icon-lg"></i>'+
                                '</a>';
                        } else if(row.status == 1) {
                            html = '' +
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="收货">'+
                                '<i class="la la-dolly icon-lg"></i>'+
                                '</a>';
                        } else if(row.status == 3) {
                            html = '' +
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="详情">'+
                                '<i class="la la-clipboard-list icon-lg"></i>'+
                                '</a>';
                        }
                        return html;
                    }
                }],
                search_form: {
                    product_title: '',
                    purchase_number: '',
                    warehouse_id: '',
                    status: '',
                    begin_time: '',
                    end_time: ''
                },
                page: {
                    currPage: 1,
                    limit: 15,
                    pageCount:0,
                    total:0,
                },
                dialog_id: 0,
                dialog_show: false,
                dialog_title: '',
                dialog_view: null,
                dialog_data: null
            }
        },
        watch: {
            $route: {
                handler(val, oldval) {
                    if('/' + this.$route.name == this.$route.path){
                        this.route_children = false;
                    } else {
                        this.route_children = true;
                    }
                    this.searchList();
                },
                deep: true
            },
            'search_form.status':function (val){
                this.searchList();
            },
            'search_form.warehouse_id':function (val){
                this.searchList();
            }
        },
        methods: {
            searchList() {
                var _this = this;
                var data = _this.search_form;
                data['page'] = _this.page.currPage;
                data['limit'] = _this.page.limit;
                getPurchasementList(data).then(function(res){
                    if(res){
                        _this.list = res.response.list;
                        _this.page.total = res.response.total;
                        _this.page.pageCount = res.response.count;
                    }
                });
            },
            operateHandler(clazz, row) {
                var _this = this;
                if(clazz.indexOf('la-edit') > -1){
                    this.$router.push({
                        path: '/purchasement/updatepurchasement',
                        query: {
                            'id': row.id
                        }
                    });
                } else if(clazz.indexOf('la-trash-o') > -1){

                    //删除操作
                    KTUtil.confirm('操作提示', '是否确认删除该条记录？', function(){
                        deletePurchasement({id: row.id}).then(function (res) {
                            if (res) {
                                KTUtil.toast(_this, '结果提醒', '删除成功', 'success');
                                _this.searchList();
                            }
                        });
                    });
                } else if(clazz.indexOf('la-dolly') > -1){

                    //根据采购类型判断, 其他采购收货直接完成
                    if(row.channel == 3) {
                        KTUtil.confirm('操作提示', '是否确认收货并修改订单为已完成状态？', function(){
                            updatePurchasementStatus({id: row.id, 'status': 3}).then(function (res) {
                                if (res) {
                                    KTUtil.toast(_this, '结果提醒', '删除成功', 'success');
                                    _this.searchList();
                                }
                            });
                        });
                    } else {
                        this.dialog_id = new Date().getTime();
                        this.dialog_show = true;
                        this.dialog_title = '采购单收货';
                        this.dialog_view = receivePurchasement;
                        this.dialog_data = {
                            form_data: row
                        };
                    }
                } else if(clazz.indexOf('la-clipboard-list') > -1){

                    this.$router.push({
                        path: '/purchasement/showpurchasement',
                        query: {
                            'id': row.id
                        }
                    });
                }
            },
            pagechangeHandler(page, pageSize) {
                this.page.currPage = page;
                this.page.limit = pageSize;
                this.searchList();
            },
            addPurchasement() {
                this.$router.push({
                    path: '/purchasement/addpurchasement'
                });
            },
            opeareResultHandler(result) {
                if(result){
                    this.searchList();
                }
                this.dialog_show = false;
            }
        }
    }
</script>
